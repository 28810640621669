<template>
  <div class="flex h-screen">
    <div
      class="m-auto rounded-lg"
      :class="{ 'animate-wiggle': $store.state.loginError }"
    >
      <label for="" class="hidden"></label>
      <input
        type="text"
        v-model="user"
        placeholder="Username"
        class="block placeholder-gray-300 border-black focus:border-black focus:outline-none focus:border-transparent outline-none ring-0 focus:ring-0"
      />

      <label for="" class="hidden"></label>
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        class="block placeholder-gray-300 border-black border-t-0 focus:border-black focus:outline-none focus:border-transparent outline-none ring-0 focus:ring-0"
      />

      <button
        class="block bg-gray-900 rounded-md rounded-t-none px-4 py-2 text-white container text-xs uppercase shadow-sm focus:outline-none hover:scale-95"
        @click="login"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      password: null,
      error: null,
    };
  },
  methods: {
    login() {
      this.$store.dispatch('loginUser', {
        user: this.user,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
