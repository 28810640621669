!<template>
  <div
    class="fixed bg-black text-white w-full h-screen grid place-items-center"
  >
    <div class="animate-bounce">LOADER</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
