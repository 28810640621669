import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import store from '../store/index.js';

import Home from '../views/Home.vue';
import Login from '../views/base/Login.vue';

console.log('Setting up Router');
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/:slug?',
    name: 'Page',
    component: Home,
    meta: {
      title: null,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Login',
    },
  },
];

// SETUP ROUTER
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.state.pageLoading = true;
  document.title = to.meta.title;

  console.log('Entering route', to.fullPath);
  next();
  store.state.pageLoading = false;
});

// INIT ROUTER

// CHECK PERMISSIONS BEFORE ROUTE ENTER
// router.beforeEach((to, from, next) => {
//   console.log('Entering route', to);

//   // NO JWT PRESENT
//   if (localStorage.getItem('jwt') == null) {
//     console.log('No JWT found');

//     if (process.env.VUE_APP_PUBLIC != true) {
//       store.state.pageLoading = false;
//       if (to.name != 'Login') {
//         next({
//           name: 'Login',
//           params: { nextUrl: to.fullPath },
//         });
//       } else {
//         next();
//       }
//     }
//     // IF PUBLIC ACCESS LOGIN PUBLIC
//     if (process.env.VUE_APP_PUBLIC == true) {
//       store
//         .dispatch('loginUser', {
//           user: 'api',
//           password: process.env.VUE_APP_PUBLIC_KEY,
//         })
//         .then(() => {
//           // FETCH ROUTES
//           store.dispatch('fetchRoutes');
//         });
//       // IF PRIVATE SHOW LOGIN
//     }
//   } else {
//     console.log('JWT found');
//     store.dispatch('setToken', localStorage.getItem('jwt'));
//     // TODO GET USER CREDENTIALS
//     store.dispatch('fetchRoutes');

//     next();
//     store.state.pageLoading = false;
//   }
// });

// router.beforeEach((to, from, next) => {
//   console.log(to, from, next);
//   if (localStorage.getItem('jwt') == null) {
//     console.log('no jwt found');
//   } else {
//     console.log('jwt found');
//   }
// });

// if (to.matched.some(record => record.meta.requiresAuth)) {
//   if (localStorage.getItem('jwt') == null) {
//     console.log('no jwt');
//     next({
//       path: '/login',
//       params: { nextUrl: to.fullPath },
//     });
//   } else {
//     console.log('jwt present');
//     let user = JSON.parse(localStorage.getItem('user'));
//     if (to.matched.some(record => record.meta.is_admin)) {
//       if (user.is_admin == 1) {
//         next();
//       } else {
//         next({ name: 'userboard' });
//       }
//     } else {
//       next();
//     }
//   }
// } else if (to.matched.some(record => record.meta.guest)) {
//   if (localStorage.getItem('jwt') == null) {
//     next();
//   } else {
//     next({ name: 'userboard' });
//   }
// } else {
//   next();
// }

export default router;
