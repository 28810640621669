<template>
  <div class="home">Hello {{ $store.state.user }}</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {},
};
</script>
