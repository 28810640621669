<template>
  <div>
    <transition name="fade">
      <Loader v-if="$store.state.pageLoading" />
    </transition>
    <router-view />
  </div>
</template>

<script>
import Loader from './components/base/Loader.vue';

export default {
  components: { Loader },
  created() {
    // HIDE LOADER
    document.querySelector('#loader').style.display = 'none';

    // SHOW APP
    document.querySelector('#app').style.display = 'inline';
  },
};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
