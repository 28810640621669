import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './assets/index.css';

// STARTUP
console.log('Starting');

// CHECK JWT
if (localStorage.getItem('jwt')) {
  console.log('JWT found');
  // GET USER CREDENTIALS
  store.dispatch('authToken', localStorage.getItem('jwt')).then(res => {
    // INIT
    init();
  });
} else {
  console.log('JWT not found');
  if (process.env.VUE_APP_PUBLIC == true) {
    // GET PUBLIC ACCESS TOKEN
    console.log('Public Access: Get token');
    store
      .dispatch('loginUser', {
        user: 'api',
        password: process.env.VUE_APP_PUBLIC_KEY,
      })
      .then(() => {
        init();
      });
  }
}

// CREATE APP
function init() {
  console.log('Init App');
  const app = createApp(App)
    .use(store)
    .use(router);

  app.mount('#app');
}
