import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    user: {},
    loginError: null,
    pageLoading: true,
  },
  mutations: {
    // SET USER
    setUser(state, payload) {
      console.log('Setting User', payload.name);
      delete payload.accessToken;
      state.user = payload;
    },
  },
  actions: {
    // LOGIN USER
    async loginUser({ state, dispatch, commit }, payload) {
      console.log('Logging in user', payload.user);
      const loginData = new FormData();
      loginData.append('user', payload.user);
      loginData.append('password', payload.password);
      const login = await axios
        .post('/', loginData)
        .then(res => {
          console.log('Login granted', payload.user);
          localStorage.setItem('jwt', res.data.accessToken);
          dispatch('setToken', res.data.accessToken);
          commit('setUser', res.data);
          return true;
        })
        .catch(err => {
          console.log('Login denied', payload.user);
          state.loginError = err;
          setTimeout(() => {
            state.loginError = false;
          }, 1000);
          return err;
        });
      return login;
    },

    // AUTH USER
    async authToken({ state, dispatch, commit }, payload) {
      console.log('Authenticating token');
      const authData = new FormData();
      authData.append('auth', payload);
      const login = await axios
        .post('/', authData)
        .then(res => {
          console.log('Token authenticated');
          commit('setUser', res.data);
          return true;
        })
        .catch(err => {
          console.log(err);
          console.log('Authentication denied');
          state.loginError = err;
          localStorage.removeItem('jwt');
          setTimeout(() => {
            state.loginError = false;
          }, 1000);
          return err;
        });
      return login;
    },

    // FETCH ROUTES
    async fetchRoutes({ state }) {
      console.log('Fetching Routes');
      axios.get('/api/' + process.env.VUE_APP_ROUTES + '/').then(res => {
        console.log(res.data);
        // setTimeout(() => {
        //   state.pageLoading = false;
        // }, 5000);
      });
    },

    // AXIOS: SET TOKEN
    setToken({ state }, token) {
      console.log('Setting Token', token);
      axios.defaults.headers.common['Authorization'] = 'BEARER ' + token;
    },
  },
  modules: {},
});
